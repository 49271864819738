.passport-button {
    float:right;
    margin-top: 20px;
}

.card-contents {
    margin-top: 10px;
    display: inline-block;
    color: white;
}
