.rbac-button {
    float:right;
    margin-top: 55px;
}

.rbac-card-contents {
    width: 70%;
    margin-top: 10px;
    display: inline-block;
    color: black;
}

.rbac-base {
    background-color:#dff0d8;
}
