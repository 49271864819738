.passportParent-button {
    float:right;
    margin-top: 90px;
}

.passport-parents-card-contents {
    width: 79%;
    margin-top: 10px;
    display: inline-block;
}
